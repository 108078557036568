<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Create Share Term'"
      /> -->
       <v-card-title class="mt-3 pt-6 pl-8">
         <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Create Share Term </span>
        </v-card-title>
        <share-term-form
          v-model="shareTerms"
          :loading="loading"
          :financing-rounds="financingRoundList"
          :share-term-id="shareTermId"
          :is-submit="isSubmit"
          @save="save"
          @navToList="navToList"
        />
    </template>
  </common-trs-page-container>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import ShareTermForm from './components/ShareTermForm'

  export default {
    name: 'ShareTermFormIndex',

    components: {
      ShareTermForm,
    },

    data: () => ({
      loading: false,
      financingRoundList: [],
      shareTerms: {},
      shareTermId: '',
      isSubmit: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },
    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },

    created () {
      this.loadFinancingRounds()
    },

    methods: {
      async loadFinancingRounds () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRoundList = resp.data.financing_rounds
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },

      async save (data) {
        this.loading = true
        try {
          const resp = await captableService.draftSimpleShareTerm(this.captableId, data)
          if (resp.status === 201) {
            this.shareTermId = resp.data.id
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save share term. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Share term saved successfully.' })
        this.$router.push({ name: 'ShareTerms' })
      },
      close () {
        this.$router.push({ name: 'ShareTerms' })
      },
    },
  }
</script>
